import firebaseApp from "./config/firebase";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/styles/main.scss";

import keycloak from "./keycloak";

Vue.config.productionTip = false;

keycloak
  .init({ onLoad: "login-required" })
  .then((authenticated) => {
    if (authenticated) {
      new Vue({
        router,
        vuetify,
        render: (h) => h(App, { props: { keycloak: keycloak } }),
      }).$mount("#app");
    } else {
      window.location.reload();
    }
  })
  .catch(() => {
    console.log("Keycloak initialization failed");
  });

Vue.prototype.$keycloak = keycloak;
