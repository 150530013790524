<template>
  <v-app v-if="!CRSecu">
    <Navigation
      v-if="isNavigationDisplayed && user && profile"
      :user="user"
      :profile="profile"
      :userSession="currentSession"
    />
    <v-main>
      <v-container
        :style="
          isNavigationDisplayed ? 'max-width:1500px;' : 'max-width:50000px;'
        "
      >
        <router-view
          :user="user"
          :profile="profile"
          v-if="(needAccount && user && profile) || !needAccount"
          :userSession="currentSession"
        />
      </v-container>
    </v-main>
    <Footer v-if="isNavigationDisplayed"></Footer>
  </v-app>
  <v-app v-else class="mt-5 d-flex align-center justify-center">
    <h1 class="mt-5 text-center error--text">ERROR :</h1>
    <h1 class="text-center error--text">
      One or more libraries are unavailable please contact an administrator
    </h1>
  </v-app>
</template>

<script>
import keycloak from "@/keycloak";
import CopyRightSecurity from "@/assets/functions/engine/CopyRightSecurity.js";

import Navigation from "@/components/common/Navigation.vue";
import Footer from "@/components/common/Footer.vue";

import Profile from "@/classes/Profile.js";
import Team from "@/classes/Team.js";
import Univers from "@/classes/Univers.js";
import Session from "@/classes/Session.js";

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },
  computed: {
    isNavigationDisplayed() {
      if (this.$route.name) {
        return this.$route.meta.layout.navigation;
      } else {
        return false;
      }
    },
    needAccount() {
      if (this.$route.name) {
        return this.$route.meta.needAccount;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      unsub: [],
      user: null,
      profile: null,
      currentSession: null,
      CRSecu: false,
    };
  },
  async created() {
    if (keycloak.authenticated) {
      // console.log("KEYCLOAK", keycloak);
      this.user = {
        uid: keycloak.tokenParsed.sub,
        email: keycloak.tokenParsed.email,
        firstname: keycloak.tokenParsed.given_name,
        lastname: keycloak.tokenParsed.family_name,
      };
      this.unsub.push(
        Profile.listenByUid(this.user.uid, async (profile) => {
          if (profile != null) {
            this.profile = profile;
            if (this.profile.role == "User" && this.profile.team != null) {
              let currentTeam = await Team.getById(this.profile.team);
              let currentUnivers = await Univers.getById(currentTeam.univers);

              this.unsub.push(
                Session.listenById(currentUnivers.session, (session) => {
                  this.currentSession = session;
                  this.$forceUpdate();
                })
              );
            } else {
              this.currentSession = null;
            }
          } else {
            this.profile = new Profile(
              null,
              this.user.uid,
              this.user.email,
              "User",
              this.user.firstname,
              this.user.lastname,
              null,
              false
            );
            this.profile.save().then(() => {
              window.location.reload();
            });
          }
        })
      );
    } else {
      this.user = null;
      this.profile = null;
    }
    this.CRSecu = await CopyRightSecurity.getSecuLevel();
    CopyRightSecurity.ActivateSecuFunction();
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
