import Vue from "vue";
import VueRouter from "vue-router";
import keycloak from "@/keycloak";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      title: "Accueil",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "Connexion",
      layout: {
        navigation: false,
      },
      needAccount: false,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    meta: {
      title: "Mon profil",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/decisions",
    name: "Decisions",
    component: () => import("@/views/Decisions.vue"),
    meta: {
      title: "Décisions",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/bonus",
    name: "Bonus",
    component: () => import("@/views/Bonus.vue"),
    meta: {
      title: "Bonus/Malus",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/results",
    name: "Results",
    component: () => import("@/views/Results.vue"),
    meta: {
      title: "Résultats",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/graphs",
    name: "Graphs",
    component: () => import("@/views/Graphs.vue"),
    meta: {
      title: "Graphiques",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/researches",
    name: "Researches",
    component: () => import("@/views/Researches.vue"),
    meta: {
      title: "Études",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/adminResearches",
    name: "AdminResearches",
    component: () => import("@/views/AdminResearches.vue"),
    meta: {
      title: "Études Admin",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: () => import("@/views/Marketplace.vue"),
    meta: {
      title: "Marketplace",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/info",
    name: "Informations",
    component: () => import("@/views/Informations.vue"),
    meta: {
      title: "Informations",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/news",
    name: "News",
    component: () => import("@/views/News.vue"),
    meta: {
      title: "News",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/documents",
    name: "Documents",
    component: () => import("@/views/Documents.vue"),
    meta: {
      title: "Documents",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/challenges",
    name: "Challenges",
    component: () => import("@/views/Challenges.vue"),
    meta: {
      title: "Challenges",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/assets",
    name: "Assets",
    component: () => import("@/views/Assets.vue"),
    meta: {
      title: "Assets",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: () => import("@/views/Sessions.vue"),
    meta: {
      title: "Sessions",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/teams",
    name: "Teams",
    component: () => import("@/views/Teams.vue"),
    meta: {
      title: "Équipes",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/Users.vue"),
    meta: {
      title: "Utilisateurs",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/periods",
    name: "Periods",
    component: () => import("@/views/Periods.vue"),
    meta: {
      title: "Périodes",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },
  {
    path: "/devTools",
    name: "DevTools",
    component: () => import("@/views/DevTools.vue"),
    meta: {
      title: "Dev Tools",
      layout: {
        navigation: true,
      },
      needAccount: true,
    },
  },

  {
    path: "*",
    name: "Error404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      title: "404",
      layout: {
        navigation: false,
      },
      needAccount: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// Keycloak authentication guard
router.beforeEach((to, from, next) => {
  if (to.meta.needAccount) {
    if (keycloak.authenticated) {
      next();
    } else {
      keycloak.login({ redirectUri: window.location.href });
    }
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? to.meta.title : "Ludimation";
  });
});

export default router;
